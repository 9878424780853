import React, { Component } from 'react';
import './WideArticleCard.css';
import tempImg from './assets/PublicationsImg.jpg';
import expand from './assets/ExpandIcon.svg';

export class WideArticleCard extends Component {
  render() {
    return (
      <div className='WAC-container' style={{left:this.props.left}}>
        <img src={tempImg} className='WAC-img' alt={this.props.title}/>
        <div className='WAC-condensed'>
          <div className='WAC-title'>{this.props.title}</div>
          <div className='WAC-author'>By {this.props.author}</div>
          <div className='WAC-plus'><img src={expand} alt='expand button'/></div>
        </div>
      </div>
    )
  }
}

export default WideArticleCard

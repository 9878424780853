import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {FaBars} from 'react-icons/fa';
import './HeaderTemp.css';
import mpicon from './assets/MPICON.svg';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {dropdown:false}
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({dropdown: !this.state.dropdown})
  }

  render() {
    let page = this.props.page;
    return (
      <div className='flex-header'>
      <div className='header'>
        <Link to='/'>
          <div className='mpicon'>
            <img src={mpicon} alt='Minority Programmers logo' />
          </div>
        </Link>
        
        {/* <div className='events'>
          {page!=='events' ?
          <Link to='/events'><div className='header-tab-font'>events</div></Link> :
          <Link to='/events'><div className='header-tab-font' style={{color:'#FDBF07'}}>events</div></Link> 
          }
        </div> */}
        <div className='join'>
        <a href='https://www.minorityprogrammers.com/' target='_blank' rel="noreferrer"><div className='header-tab-font'>join us</div></a> :

        </div>
        {/* <div className='pubs'>
          {page!=='publications' ?
          <Link to='/publications'><div className='header-tab-font'>publications</div></Link> :
          <Link to='/publications'><div className='header-tab-font' style={{color:'#FDBF07'}}>publications</div></Link> 
          }
        </div> */}
        <div className='about'>
          {page!=='about us' ?
          <Link to='/about'><div className='header-tab-font'>about us</div></Link> :
          <Link to='/about'><div className='header-tab-font' style={{color:'#FDBF07'}}>about us</div></Link> 
          }
        </div>

        <div className='mobile-icon' onClick={(e)=>this.togglePanel(e)}><FaBars style={{color:'#FFFFFF', width:'100%', height:'100%'}}/></div>
        <button className='donate-btn'><a className='donate-btn-txt' href='https://www.minorityaccelerator.com/donate' target='_blank' rel="noreferrer">Donate</a></button>
      </div>
      {this.state.dropdown ?
      <div className='dropdown-menu-background'>
        {/* <div className='events-drop'>
          {page!=='events' ?
          <Link to='/events'><div className='header-tab-font'>events</div></Link> :
          <Link to='/events'><div className='header-tab-font' style={{color:'#FDBF07'}}>events</div></Link> 
          }
        </div> */}
        <div className='join-drop'>
         <a href='https://www.minorityprogrammers.com/' target='_blank' rel="noreferrer"><div className='header-tab-font'>join us</div></a> :

        </div>
        {/* <div className='pubs-drop'>
          {page!=='publications' ?
          <Link to='/publications'><div className='header-tab-font'>publications</div></Link> :
          <Link to='/publications'><div className='header-tab-font' style={{color:'#FDBF07'}}>publications</div></Link> 
          }
        </div> */}
        <div className='about-drop'>
          {page!=='about us' ?
          <Link to='/about'><div className='header-tab-font'>about us</div></Link> :
          <Link to='/about'><div className='header-tab-font' style={{color:'#FDBF07'}}>about us</div></Link> 
          }
        </div>
        <button className='donate-btn'><a className='donate-btn-txt' href='https://www.minorityaccelerator.com/donate' target='_blank' rel="noreferrer">Donate</a></button>

      </div> :
      null}
      
      </div>
    )
  }
}

export default Header
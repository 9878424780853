import React, { Component } from 'react';
import './ArticleCard.css';
import tempImg from './assets/PublicationsImg.jpg';
import expand from './assets/ExpandIcon.svg';
import contract from './assets/ContractIcon.svg';

export class ArticleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {open:true}
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({open: !this.state.open})
  }

  render() {
    return (
      <div className='AC-container' style={{left:this.props.left}}>
        <img src={tempImg} className='AC-img' alt={this.props.title}/>
        {this.props.comingsoon ? (<div className='coming-soon-blur'><div className='coming-soon-txt'>Coming Soon</div></div>):null}
        {this.state.open ? (
          <div className='AC-expanded'>
            <div className='AC-title'>{this.props.title}</div>
            <div className='AC-author'>By {this.props.author}</div>
            <div className='AC-txt'>{this.props.desc}</div>
            <div onClick={(e)=>this.togglePanel(e)} className='AC-minus'><img src={contract} alt='contract button'/></div>
          </div>
        ) : (
          <div className='AC-condensed'>
            <div className='AC-title'>{this.props.title}</div>
            <div className='AC-author'>By {this.props.author}</div>
            <div onClick={(e)=>this.togglePanel(e)} className='AC-plus'><img src={expand} alt='expand button'/></div>
          </div>
        )}
      </div>
    )
  }
}

export default ArticleCard

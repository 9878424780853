import React, { Component } from 'react';
import './UpcomingEvent.css';
import tempImg from './assets/NewsCardImg.jpg';
import Clock from './assets/Clock.svg';
import expand from './assets/ExpandIcon.svg';
import contract from './assets/ContractIcon.svg';

export class UpcomingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {open:true}
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({open: !this.state.open})
  }

  render() {
    let description = this.props.desc;
    if (description.length > 115) {
      description = this.props.desc.substr(0, 110);
      description = description.substr(0, Math.min(description.length, description.lastIndexOf(" "))) + " ...";
    }
    return (
      <div className='UE-container' style={{left:this.props.left}}>
        <img src={tempImg} className='UE-img' alt={this.props.title}/>
        {this.state.open ? (
          <div className='UE-expanded'>
            <div className='UE-title'>{this.props.title}</div>
            <img src={Clock} className='UE-clock' alt='clock'/>
            <div className='UE-date-time'>{this.props.datetime}</div>
            <div className='UE-content'>{description}</div>
            <button className='join-btn'><div className='join-btn-txt'>Join</div></button>
            <div onClick={(e)=>this.togglePanel(e)} className='UE-minus'><img src={contract} alt='contract button'/></div>
          </div>
        ) : (
          <div className='UE-condensed'>
            <div className='UE-title'>{this.props.title}</div>
            <img src={Clock} className='UE-clock' alt='clock'/>
            <div className='UE-date-time'>{this.props.datetime}</div>
            <button className='join-btn'><div className='join-btn-txt'>Join</div></button>
            <div onClick={(e)=>this.togglePanel(e)} className='UE-plus'><img src={expand} alt='expand button'/></div>
          </div>
        )}
      </div>
    )
  }
}

export default UpcomingEvent

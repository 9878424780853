import React, { Component } from "react";
import "./ThinkTankHome.css";
import Header from "./HeaderTemp";
import Footer from "./Footer";
import SubscribeSection from "./SubscribeSection";
import PgmCard from "./ProgramCard";
import innovationimg from "./assets/InnovationImg.jpg";
import communityimg from "./assets/CommunityImg.jpg";
import academiaimg from "./assets/AcademiaImg.jpg";
import comp3 from "./assets/Component3.svg";
import comp4 from "./assets/Component 4.svg";
import comp5 from "./assets/Component 5.svg";
import globe from "./assets/Globe.svg";
import arrow from "./assets/ArrowComponent.svg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Navigation]);
// To force the swiper loop to update
setInterval(() => {
  let swiper = document.querySelector(".home-news-swiper");
  if (swiper != null) {
    swiper = swiper.swiper;
    swiper.loopDestroy();
    swiper.loopCreate();
  }
  let swiper2 = document.querySelector(".home-program-swiper");
  if (swiper2 != null) {
    swiper2 = swiper2.swiper;
    swiper2.loopDestroy();
    swiper2.loopCreate();
  }

  // const swiper = document.querySelectorAll('.swiper-container');
  // for (var i = 0; i < swiper.length; i++) {
  //   const s1 = swiper[i].swiper;
  //   if (s1 != null) {
  //     s1.loopDestroy();
  //     s1.loopCreate();
  //   }
  // }
}, 500);

const mappingProgram = (programs, completed) => {
  const displayedPrograms = programs.filter(
    (program) => program.completed === completed
  );
  // let today = new Date();
  // let d = program.date.year + '-' + program.date.month + '-' + program.date.day;
  // let eventDate = new Date(d);
  console.log(displayedPrograms.length);
  return displayedPrograms?.length ? (
    displayedPrograms.map((program) => (
      <SwiperSlide className="item" key={program.id} style={{ width: "364px" }}>
        <PgmCard
          key={program.id}
          title={program.title}
          desc={program.desc}
          img={program.img}
        />
      </SwiperSlide>
    ))
  ) : (
    <div className="no-programs">Sorry, no active programs</div>
  );
};

export class ThinkTankHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: true,
      email: "",
      seen: false,
    };
    this.programUpdate = this.programUpdate.bind(this);
  }

  programUpdate(component) {
    if (component && !this.state.completed) {
      this.setState({ completed: !this.state.completed });
    } else if (!component && this.state.completed) {
      this.setState({ completed: !this.state.completed });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(this.props.programs);
  }
  render() {
    return (
      <div className="home-background">
        <div className="home-header-element">
          <Header page="home" />
        </div>

        {/* <div className="home-black-background" /> */}

        <div className="home-header-container">
          <div className="home-header">Minority Think Tank</div>
          <div className="home-dt">diversifying thought.</div>
        </div>

        <div className="home-mission-flex-container">
          <div className="home-subheader" style={{ paddingLeft: "120px" }}>
            Mission
          </div>
          <div className="home-style-line" />
          <div className="mission">
            The Minority Think Tank (MTT) Foundation is an independent
            501(c)3 nonprofit, nonpartisan research organization dedicated to empowering
            humanity through innovative ideas from expert analysis, technology
            driven solutions, revolutionary thinking, and education for
            underrepresented communities.
          </div>
        </div>

        <div className="focus-flex-container">
          <div className="home-subheader" style={{ paddingLeft: "147px" }}>
            Focus
          </div>
          <div className="home-style-line" />
          <div className="focus-container">
            <div className="focus-box">
              <div className="card-img-container">
                <img src={comp3} className="comp-pos" alt="lightbulb icon" />
                <img
                  src={innovationimg}
                  className="focus-img"
                  alt="innovation"
                />
              </div>
              <div className="title-backgrounds">
                <div className="title-txt">Innovation & Development</div>
              </div>
            </div>

            <div className="focus-box">
              <div className="card-img-container">
                <img src={academiaimg} className="focus-img" alt="academia" />
                <img src={comp5} className="comp-pos" alt="trophy icon" />
              </div>
              <div className="title-backgrounds">
                <div className="title-txt">Academia & Policy</div>
              </div>
            </div>

            <div className="focus-box">
              <div className="card-img-container">
                <img src={communityimg} className="focus-img" alt="community" />
                <img src={comp4} className="comp-pos" alt="gears icon" />
              </div>
              <div className="title-backgrounds">
                <div className="title-txt">Community Programs</div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-pgm-flex-container">
          <div className="home-subheader" style={{ paddingLeft: "78px" }}>
            Programs
          </div>
          <div className="home-style-line" />

          {/* determines if completed or in progress should be pink */}
          {this.state.completed === true ? (
            <>
              <div className="pgm-txt-flex-container">
                <div
                  className="pgm-in-progress"
                  onClick={() => {
                    this.programUpdate(false);
                  }}
                >
                  In Progress
                </div>
                <div
                  className="pgm-completed"
                  onClick={() => {
                    this.programUpdate(true);
                  }}
                >
                  Completed
                </div>
              </div>
              <div className="pgm-line" />
              <div className="pgm-txt-flex-container">
                <div className="pgm-progress-line" />
                <div className="pgm-complete-line" />
              </div>
            </>
          ) : (
            <>
              <div className="pgm-txt-flex-container">
                <div
                  className="pgm-in-progress"
                  style={{ color: "#FF00B8", fontWeight: "bold" }}
                  onClick={() => {
                    this.programUpdate(false);
                  }}
                >
                  In Progress
                </div>
                <div
                  className="pgm-completed"
                  style={{ color: "#FFFFFF", fontWeight: "normal" }}
                  onClick={() => {
                    this.programUpdate(true);
                  }}
                >
                  Completed
                </div>
              </div>
              <div className="pgm-line" />
              <div className="pgm-txt-flex-container">
                <div
                  className="pgm-progress-line"
                  style={{ borderColor: "#FF00B8", backgroundColor: "#FF00B8" }}
                />
                <div
                  className="pgm-complete-line"
                  style={{ borderColor: "#FFFFFF", backgroundColor: "#FFFFFF" }}
                />
              </div>
            </>
          )}
          <div className="program-swiper-container">
            <div className="home-news-arrow-container">
              <img
                className="program-right-arrow"
                src={arrow}
                alt="right arrow button"
              />
              <img
                className="program-left-arrow"
                src={arrow}
                alt="left arrow button"
              />
            </div>
            <div className="home-pgm-container">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: ".program-left-arrow",
                  nextEl: ".program-right-arrow",
                }}
                className="home-news-swiper"
              >
                {mappingProgram(this.props.programs, this.state.completed)}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="home-news-flex-container">
          <div className="home-subheader">News</div>
          <div className="home-style-line" />
          <div className="home-news-container">
            <div className="home-news-arrow-container">
              <img
                className="home-right-arrow"
                src={arrow}
                alt="right arrow button"
              />
              <img
                className="home-left-arrow"
                src={arrow}
                alt="left arrow button"
              />
            </div>
            <div className="home-news-swiper-container">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: ".home-left-arrow",
                  nextEl: ".home-right-arrow",
                }}
                className="home-news-swiper"
              >
                {this.props.news.map((news, i) => (
                  <SwiperSlide
                    className="item"
                    key={i}
                    style={{ width: "364px" }}
                  >
                    <PgmCard
                      key={i}
                      title={news.title}
                      desc={news.desc}
                      img={news.img}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <img src={globe} className="home-globe" alt="globe" />
        <SubscribeSection />
        {/* <div className="home-spacer" /> */}

        <div className="home-footer-flex-container">
          <Footer />
        </div>
      </div>
    );
  }
}

export default ThinkTankHome;

import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './Events.css';
import Header from './HeaderTemp';
import Footer from './Footer';
import UpcomingEvent from './UpcomingEvent';
import PastEvent from './PastEvent';
import EmailPopUp from './EmailPopUp';
import eyebutton from './assets/EyeButton.svg';
import globe from './assets/Globe.svg';
import whiteplus from './assets/PgmExpandIcon.svg';
import arrow from './assets/ArrowComponent.svg';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

// To force the swiper loop to update
setInterval(()=>{
  const swiper = document.querySelectorAll('.swiper-container');
  for (var i = 0; i < swiper.length; i++) {
    const s1 = swiper[i].swiper;
    if (s1 != null) {
      s1.loopDestroy();
      s1.loopCreate();
    }
  }
},500)

export class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:'',
      seen:true
    }
  }

  togglePop = () => {
    this.setState({
      seen: !this.state.seen
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div className='events-background'>
        <div className='events-header-element'>
        <Header page='events'/>
        </div>

        <div className='events-black-background' />

        <div className='events-header-flex-container'>
          <div className='events-header-container'>
            <div className='events-page-title'>Events</div>
            <div className='events-page-subtitle'>bright minds lighting up rooms, don’t play yourself, click resume.</div>
          </div>
          <img src={eyebutton} className='eye-w-play-btn' alt='link to videos'/> 
        </div>

        <div className='events-upcoming-flex-container'>
          <div className='events-subheader'>Upcoming Events</div>
          <div className='events-style-line'/>
          <div className='events-upcoming-container'>
            <div className='events-upcoming-arrow-container'>
              <img src={arrow} className='event-right-arrow ea1r' alt='right arrow button'/>
              <img src={arrow} className='event-left-arrow ea1l' alt='left arrow button'/>
            </div>
            <div className='upcoming-events-container'>
              <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
                "delay": 4000,
                "disableOnInteraction": false
              }} navigation= {{
                prevEl: '.ea1l',
                nextEl: '.ea1r'
              }} className="mySwiper">
                {this.props.upcoming.map((event, i) => <SwiperSlide className='item' key={i} style={{width:'283px'}}><UpcomingEvent key={i} title={event.title} datetime={event.datetime} desc={event.desc}/></SwiperSlide>)}
              </Swiper>
            </div>
          </div>
        </div>

        <div className='event-email-flex-container'>
          <div className='event-email-title'>Can’t do better than our newsletter!</div>
          <div className='event-email-subtitle'>Not thinkin’ enough today? Sign Up, then you’ll be up to date</div>
          <MailchimpSubscribe 
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({subscribe, status, message}) => (
              <>
                <form onSubmit={(event)=> {
                  event.preventDefault();
                  subscribe({ EMAIL:this.state.email });
                  this.setState({email:''});
                }}>
                  <input type='text' className='email-field event-email-field' placeholder='Enter your email address' value={this.state.email} onChange={e => this.setState({email:e.target.value})}/>
                  <div className='event-email-btn-container'>
                    <button type='submit' className='email-field-btn'><div className='email-field-btn-txt'>Sign up</div></button>
                  </div>
                </form>
                <div style={{marginTop:'260px', display:'flex', justifyContent:'center', position:'absolute', width:'100%'}}>
                  {status === "sending" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Sending...' err={false}/> : null}
                  {status === "error" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content={message} err={true}/> : null}
                  {status === "success" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Subscribed!' err={false}/> : null}
                </div>
              </>
          )} 
        />
        </div>
        <div className='event-spacer' />

        <div className='past-events-flex-container'>
          <div className='events-subheader' style={{width:'132px', left:'113px'}}>Past Events</div>
          <div className='events-style-line'/>
          <div className='past-events-container'>
            <div className='events-upcoming-arrow-container'>
              <img src={arrow} className='event-right-arrow ea2r' alt='right arrow button'/>
              <img src={arrow} className='event-left-arrow ea2l' alt='left arrow button'/>
            </div>
            <div className='past-events-swiper-container'>
              <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
                "delay": 4000,
                "disableOnInteraction": false
              }} navigation= {{
                prevEl: '.ea2l',
                nextEl: '.ea2r'
              }} className="mySwiper">
                {this.props.past.map((event, i) => <SwiperSlide className='item' key={i} style={{width:'283px'}}><PastEvent key={i} title={event.title} desc={event.desc} /></SwiperSlide>)}
              </Swiper>
            </div>
          </div>
        </div>

        <img src={globe} className='events-globe' alt='globe'/>
        <div className='event-host-flex-container'>
          <div className='event-host-title'>Want to host an event with MTT?</div>
          <div className='event-host-subtitle'>It could be a virtual party, workshop, meetup or seminar. Co-host any event you want and share for people to join. </div>
          <a className='event-host-btn' href='https://www.minorityprogrammers.com/events' target='_blank' rel="noreferrer">
            <img src={whiteplus} className='event-host-btn-plus' alt='host an event button'/>
            <div className='event-host-btn-txt'>Host an Event</div>
          </a>
        </div>
        <div className='event-footer-flex-container'>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Events

import React, { Component } from 'react'
import './PastEvent.css';
import tempImg from './assets/NewsCardImg.jpg';
import expand from './assets/ExpandIcon.svg';


export class PastEvent extends Component {
  render() {
    return (
      <div className='PE-container' style={{left:this.props.left}}>
        <img src={tempImg} className='PE-img' alt={this.props.title}/>
        <div className='PE-info'>
          <div className='PE-title'>{this.props.title}</div>
          <div className='PE-txt'>{this.props.desc}</div>
          <img src={expand} className='PE-plus' alt='expand button'/>
        </div>
      </div>
    )
  }
}

export default PastEvent

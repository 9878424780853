import React, { Component } from 'react';
import './ProgramCard.css';
import tempImg from './assets/NewsCardImg.jpg';
import {ReactComponent as Expand} from  './assets/PgmExpandIcon.svg';
import {ReactComponent as Contract} from  './assets/PgmContractIcon.svg'


export class ProgramCard extends Component {
  constructor(props) {
    super(props);
    this.state = {open:true}
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({open: !this.state.open})
  }
  
  render() {
    let description = this.props.desc;
    if (description.length > 200) {
      description = this.props.desc.substr(0, 200);
      description = description.substr(0, Math.min(description.length, description.lastIndexOf(" "))) + " ...";
    }
    if (!this.props.title){
    return (
      <div className='program-container'>
        {this.props.img!==null ?
        <img src={this.props.img} className='pgm-img' alt={this.props.name}/>
        :
        <img src={tempImg} className='pgm-img' alt={this.props.name}/>
        }
        {this.state.open ? (
          <div className='pgm-info-expanded'>
            <div className='pgm-info-title'> 
              <div onClick={(e)=>this.togglePanel(e)} className='pgm-condense'>
                {this.state.open ?<Contract />:<Expand />}
              </div>
              {this.props.name}, {this.props.role}
            </div>
            <div className='pgm-info-content'>{description}</div>
      
          </div>
        ) : (
          <div className='pgm-info-condensed'>
            <div className='pgm-info-title'>{this.props.name}, {this.props.role}</div>
          </div>
        )}
        
      </div>
    )}
    else 
      return (
        <div className='program-container'>
        {this.props.img!==null ?
        <img src={this.props.img} className='pgm-img' alt={this.props.title}/>
        :
        <img src={tempImg} className='pgm-img' alt={this.props.title} />
        }
        {this.state.open ? (
          <div className='pgm-info-expanded'>
            <div className='pgm-info-title'> 
              <div onClick={(e)=>this.togglePanel(e)} className='pgm-condense'>
                <Contract />
              </div>
              {this.props.title}
            </div>
            <div className='pgm-info-content'>{description}</div>
          </div>
        ) : (
          <div className='pgm-info-condensed'>
            <div className='pgm-info-title'>
              <div onClick={(e)=>this.togglePanel(e)} className='pgm-condense'>
                  <Expand />
              </div>
              {this.props.title}
            </div>
          </div>
        )}
        
      </div>
      )
  }
}

export default ProgramCard

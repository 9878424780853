import React, { useEffect } from 'react';
import './Publications.css';
import Header from'./HeaderTemp';
import Footer from './Footer';
import PubCard from './PublicationCard';
import ArticleCard from './ArticleCard';
import WideArticleCard from './WideArticleCard';
import FeaturedArticleImg from './assets/FeaturedArticleImg.jpg';
import tempImg from './assets/PublicationsImg.jpg';
import Arrow from './assets/ArrowComponent.svg';
import Globe from './assets/Globe.svg';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const Publications = (props) => {
  
  useEffect(() => {
    window.scroll({top:0, left:0, behavior:'smooth'})
  })

  // To force the swiper loop to update
  setInterval(()=>{
    const swiper = document.querySelectorAll('.swiper-container');
    for (var i = 0; i < swiper.length; i++) {
      const s1 = swiper[i].swiper;
      if (s1 != null) {
        s1.loopDestroy();
        s1.loopCreate();
      }
    }
  },500)

  return (
    <div className='publications-background'>
      <div className='pub-header-element'>
        <Header page='publications'/>
      </div>

      <div className='publications-black-background1'/>
      <div className='publications-black-background2'/>
      
      <div className='pub-header-flex-container'>
        <div className='publications-page-title'>Publications</div>
        <div className='publications-page-subtitle'>By Scholars, professionals, students, practicioners, ballers, technologists, and good listeners.</div>
      </div>

      <div className='latest-pub-flex-container'>
        <div className='publications-subheader'>Latest Publications</div>
        <div className='publications-style-line' />
        <div className='featured-article'>
          <img src={FeaturedArticleImg} className='featured-article-img' alt={props.featured.title}/>
          <div className='featured-article-info'>
            <div className='featured-article-txt'>Featured Article</div>
            <div className='featured-article-title'>{props.featured.title}</div>
            <div className='featured-article-dateauthor'>{props.featured.date}<br/>- By {props.featured.author}</div>
            <div className='featured-article-desc'>{props.featured.desc}</div>
          </div>
        </div>

        <ShowMoreLatest articles={props.articles} />
        {/* <div className='pubs-container'>
          {props.articles.map((content, i) => <PubCard key={i} author={content.author} title={content.title} img={tempImg} desc={content.desc}/>)}
        </div> */}
        {/* <button className='show-more-btn'><div className='show-more-btn-txt'>Show more</div></button> */}
      </div>

      <div className='pub-soon-flex-container'>
        <div className='publications-subheader' style={{width:'148px', left:'101px'}}>Coming Soon</div>
        <div className='publications-style-line'/>
        <div className='coming-soon-flex-container'>
          <div className='coming-soon-arrows'>
            <img src={Arrow} className='pub-right-arrow pa1r' alt='right arrow button'/>
            <img src={Arrow} className='pub-left-arrow pa1l' alt='left arrow button'/>
          </div>
          <div className='coming-soon-swiper-container'>
            <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
              "delay": 4000,
              "disableOnInteraction": false
            }} navigation= {{
              prevEl: '.pa1l',
              nextEl: '.pa1r'
            }} className="mySwiper">
              {props.comingsoon.map((content, i) => <SwiperSlide className='item' key={i} style={{width:'311px'}}><ArticleCard key={i} comingsoon={true} author={content.author} title={content.title} desc={content.desc}/></SwiperSlide>)}
            </Swiper>
          </div>
        </div>
      </div>

      <div className='pub-food-flex-container'>
        <div className='publications-subheader' style={{width:'168px', left:'81px'}}>Food For Think</div>
        <div className='publications-style-line'/>
        <div className='food-for-think-container'>
          <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
              "delay": 4000,
              "disableOnInteraction": false
            }} className="mySwiper">
            {props.foodforthink.map((content, i) => <SwiperSlide key={i} className='item' style={{width:'550px'}}><WideArticleCard key={i} title={content.title} author={content.author}/></SwiperSlide>)}
          </Swiper>
        </div>
      </div>

      <div className='pub-news-flex-container'>
        <div className='publications-subheader' style={{width:'230px', height:'53px', left:'19px'}}>Newsletters</div>
        <div className='publications-style-line'/>
        <ShowMoreNews articles={props.articles} />
        {/* <div className='newsletters-container'>
          {props.articles.map((content, i) => <PubCard key={i} author={content.author} title={content.title} img={tempImg} desc={content.desc}/>)}
        </div>
        <button className='show-more-btn' style={{marginBottom:'106px'}}><div className='show-more-btn-txt'>Show more</div></button> */}
      </div>

      <div className='pub-research-flex-container'>
        <div className='publications-subheader' style={{width:'176px', left:'73px'}}>Research Articles</div>
        <div className='publications-style-line' />
        <div className='research-flex-box'>
          <div className='coming-soon-arrows'>
            <img src={Arrow} className='pub-right-arrow pa2r' alt='right-arrow'/>
            <img src={Arrow} className='pub-left-arrow pa2l' alt='left-arrow'/>
          </div>
          <div className='research-articles-container'>
            <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
              "delay": 4000,
              "disableOnInteraction": false
            }} navigation= {{
              prevEl: '.pa2l',
              nextEl: '.pa2r'
            }} className="mySwiper">
              {props.articles.map((content, i) => <SwiperSlide key={i} className='item' style={{width:'311px'}}><ArticleCard key={i} comingsoon={false} author={content.author} title={content.title} desc={content.desc} /></SwiperSlide>)}
            </Swiper>
          </div>
        </div>
      </div>

      <img className='publications-globe' src={Globe} alt='globe'/>

      <div className='pub-join-flex-container'>
        <div className='pub-join-header'>Thinking about joining us?</div>
        <div className='pub-join-subheader'>Join the newsletter and get all the updates</div>
        <input type='text' className='email-field pub-email-field' placeholder='Enter your email address'></input>
        <div className='pub-btn-container'>
          <button className='email-field-btn'><div className='email-field-btn-txt'>Sign up</div></button>
        </div>
      </div>

      <div className='pub-spacer' />

      <div className='pub-footer-element'>
        <Footer />
      </div>
    </div>
  )
}

export default Publications


// Show more source https://stackoverflow.com/questions/44370592/reactjs-how-to-add-a-show-more-show-less-button
class ShowMoreLatest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: this.props.articles,
      itemsToShow: 3,
    }
    this.showMore = this.showMore.bind(this);
    this.showLess = this.showLess.bind(this);
  }
  showMore() {
    let more = this.state.itemsToShow+3;
    this.setState({ itemsToShow: more})
  }
  showLess() {
    this.setState( { itemsToShow: 3})
  }
  render() {
    return <>
      <div className='pubs-container'>
        {this.state.articles.slice(0, this.state.itemsToShow).map((content, i) => <PubCard key={i} author={content.author} title={content.title} img={tempImg} desc={content.desc}/>)}
      </div>
      {this.state.itemsToShow < this.state.articles.length ?
      <button className='show-more-btn' onClick={this.showMore}><div className='show-more-btn-txt'>Show more</div></button> :
      <button className='show-more-btn' onClick={this.showLess}><div className='show-more-btn-txt'>Show less</div></button>}
    </>
  }
}


class ShowMoreNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: this.props.articles,
      itemsToShow: 3,
    }
    this.showMore = this.showMore.bind(this);
    this.showLess = this.showLess.bind(this);
  }
  showMore() {
    let more = this.state.itemsToShow+3;
    this.setState({ itemsToShow: more})
  }
  showLess() {
    this.setState( { itemsToShow: 3})
  }
  render() {
    return <>
      <div className='newsletters-container'>
        {this.state.articles.slice(0, this.state.itemsToShow).map((content, i) => <PubCard key={i} author={content.author} title={content.title} img={tempImg} desc={content.desc}/>)}
      </div>
      {this.state.itemsToShow < this.state.articles.length ? 
      <button className='show-more-btn' onClick={this.showMore} style={{marginBottom:'106px'}}><div className='show-more-btn-txt'>Show more</div></button> :
      <button className='show-more-btn' onClick={this.showLess} style={{marginBottom:'106px'}}><div className='show-more-btn-txt'>Show less</div></button>}
    </>
  }
}
import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './Join.css';
import './About.css';
import Header from './HeaderTemp';
import Footer from './Footer';
import ProgramCard from './ProgramCard';
import EmailPopUp from './EmailPopUp';
import globe from './assets/Globe.svg';
import nick from './assets/NickImg.jpg';
import dom from './assets/Dom.png';
import roshaan from './assets/Roshaan.png';
import research from './assets/ResearchIcon.svg';
import build from './assets/GearIcon.svg';
import implement from './assets/PeopleIcon.svg';
import icon from './assets/MPICON.svg';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay]);

 // To force the swiper loop to update
  setInterval(()=>{
    const swiper = document.querySelectorAll('.swiper-container');
    for (var i = 0; i < swiper.length; i++) {
      const s1 = swiper[i].swiper;
      if (s1 != null) {
        s1.loopDestroy();
        s1.loopCreate();
      }
    }
  },500)

  const mappingPositions = (member, i , selected) => {
    let img = icon;
    if (member.name==='Nick Morris') {
      img=nick;
    }
    else if (member.name==='Dom Coronel') {
      img=dom;
    }
    else if (member.name==='Roshaan Khan') {
      img=roshaan;
    }
    if (selected === 'all') {
      return (
        <SwiperSlide className='item' key={i} style={{width:'364px'}}>
          <ProgramCard key={i} name={member.name} role={member.role} img={img} desc={member.desc}/>
        </SwiperSlide>
      )
    }
    else if (member.category.includes(selected)) {
      return(
        <SwiperSlide className='item' key={i} style={{width:'364px'}}>
          <ProgramCard key={i} name={member.name} role={member.role} img={img} desc={member.desc}/>
        </SwiperSlide>
      )
    }
  }

export class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'all',
      email: '',
      seen: false
    }
    this.positionSelect = this.positionSelect.bind(this);
  }

  togglePop = () => {
    this.setState({
      seen: !this.state.seen
    });
  };

  positionSelect(component) {
    if (this.state.selected !==component) {
      this.setState({ selected:component })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

 
  render() {
  return (
    <div className='join-background'>
      <div className='join-header-element'>
        <Header page='join us' />
      </div>

      <div className='join-black-background1'/>
      {/* <div className='join-black-background2'/> */}
      {/* Temporarily shifting up */}
      <div className='join-black-background2' style={{top:'2250px', height:'1000px'}} />

      <div className='join-header-flex-container'>
        <div className='join-page-title'>Join Us</div>
        <div className='join-page-subtitle'>become a part of the team, better yourself and your world.</div>
      </div>

      <div className='join-be-part-flex-container'>
        <div className='join-subheader'>Be a part of MTT</div>
        <div className='join-style-line'/>
        <div className='join-part-txt'>At MTT things are always happening, and we strive to be the place for anyone with a drive to change the world to come to. From volunteering in our programs and participating in research teams, to our fellowships and academic mentors, there is a spot for you.</div>
      </div>



            {/* Temporarily commenting out until we get content for this section */}
      {/* <div className='join-member-flex-container'>
        <div className='join-subheader' style={{width:'244px', paddingLeft:'3px'}}>Become a MTT Member</div>
        <div className='join-style-line'/>
      </div> */}

      <div className='join-fellowship-flex-container'>]
        <div className='join-subheader' style={{width:'199px', paddingLeft:'48px'}}>Fellowship Program</div>
        <div className='join-style-line'/>
        <div className='join-pgm-txt'>Are you a student looking to develop your skills and knowledge? Take part in our Fellowship Program and get valuable hands on experience with a pracitced expert in your field of interest. Maybe you are a practioner in your field and want to help shape its future by providing guidance to a new generation? MTT is always looking for more mentors to join our Fellowship Program to help lead students in conducting their own research experience.</div>
      </div>

      <div className='join-what-we-do-flex-container'>
        <div className='join-subheader' style={{width:'230px', height:'53px', paddingLeft:'24px'}}>What We Do</div>
        <div className='join-style-line'/>
        <div className='join-what-we-do-title'>Check out what all MTT has going on and see all the ways you can join us</div>
        <div className='join-circle-flex-container'>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle'/>
              <div className='circle-border'/>
              <img src={research} className='circle-icon' style={{height:'100px', top:'20px', left:'65px'}} alt='research icon'/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Research</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>From policy to adanced STEM concepts, we add the the global knowledge base one thought at a time .</div>
          </div>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle' style={{background:'#3F22CF'}}/>
              <div className='circle-border' style={{border:'3px solid #3F22CF'}}/>
              <img src={build} className='circle-icon' style={{height:'60px'}} alt='build icon'/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Build</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>You can’t trust any old thought, you gotta do something about it. We do something about. We make ideas comes true.</div>
          </div>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle' style={{background:'#151371'}}/>
              <div className='circle-border' style={{border:'3px solid #151371'}}/>
              <img src={implement} className='circle-icon' style={{height:'45px', left:'35px'}} alt='implement icon'/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Implement</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>Research, Policy, is all cool, but we don’t wait simply push legislators to represent minority though, we go to the community and actually do it.</div>
          </div>
        </div>
      </div>

      <img src={globe} className='home-globe' alt='globe' />

      <div className='join-email-flex-container'>
        <div className='join-email-header'>Thinking about joining the network?</div>
        <div className='join-email-subheader'>Click that button and dive head first! </div>
        <MailchimpSubscribe 
          url={process.env.REACT_APP_MAILCHIMP_URL}
          render={({subscribe, status, message}) => (
            <>
              <form onSubmit={(event)=> {
                event.preventDefault();
                subscribe({ EMAIL:this.state.email });
                this.setState({email:'', seen:true});
              }}>
                <input type='text' className='email-field join-email-field' placeholder='Enter your email address' value={this.state.email} onChange={e => this.setState({email:e.target.value})}></input>
                <div className='join-btn-container'>
                    <button type='submit' className='email-field-btn'><div className='email-field-btn-txt'>Sign up</div></button>
                </div>
              </form>
              <div style={{marginTop:'380px', display:'flex', justifyContent:'center', position:'absolute', width:'100%'}}>
                {status === "sending" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Sending...' err={false}/> : null}
                {status === "error" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content={message} err={true}/> : null}
                {status === "success" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Subscribed!' err={false}/> : null}
              </div>
            </>
          )}
          />
      </div>
      <div className='join-spacer' />
      
      <div className='join-footer-container'>
        <Footer />
      </div>
    </div>
  )}
}

export default Join

function BoardComponents( {title, width, selected, component} ) {
  if (selected===title) {
    return (<div className='board-component-container' style={{width:width}}>
      <div className='board-component-txt-yellow' style={{width:width}} onClick={() => {component.positionSelect(title)}}>{title}</div>
      <div className='board-component-line-yellow' style={{width:width}} onClick={() => {component.positionSelect(title)}}></div>
    </div>)
  } else {
    return (<div className='board-component-container' style={{width:width}}>
      <div className='board-component-txt' style={{width:width}} onClick={() => {component.positionSelect(title)}}>{title}</div>
      <div className='board-component-line' style={{width:width}} onClick={() => {component.positionSelect(title)}}></div>
    </div>)
  }
}
import React, { Component } from 'react';
import './PublicationCard.css';
import expand from './assets/PgmExpandIcon.svg';
import contract from './assets/PgmContractIcon.svg';
import tempImg from './assets/NewsCardImg.jpg';

export default class PublicationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {open:true}
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    this.setState({open: !this.state.open})
  }

  render() {
    return (
      <div className='pub-card-container' style={{left:this.props.left}}>
        {this.props.img!==null ?
        <img src={this.props.img} className='pub-card-img' alt={this.props.title}/>
        :
        <img src={tempImg} className='pub-card-img' alt={this.props.title}/>
        }
        {this.state.open ? (
          <div className='pub-card-expanded'>
            <div className='pub-card-title'>{this.props.title}, <em>{this.props.author}</em></div>
            <div className='pub-card-txt'>{this.props.desc}</div>
            <div onClick={(e)=>this.togglePanel(e)} className='pub-condense'><img src={contract} alt='contract button'/></div>
          </div>
        ) : (
          <div className='pub-card-condensed'>
            <div className='pub-card-title'>{this.props.title}, <em>{this.props.author}</em></div>
            <div onClick={(e)=>this.togglePanel(e)} className='pub-expand'><img src={expand} alt='expand button'/></div>
          </div>
        )}
        
      </div>
    )
  }
}

import React, { Component } from "react";
import './EmailPopUp.css';

export default class EmailPopUp extends Component {
  handleClick = () => {
   this.props.toggle();
  };
render() {
  let msg = this.props.content;
  return (
    <div className="modal">
      {msg!=='Sending...' ? <span className="close" onClick={this.handleClick}>&times;    </span> : null}
      {this.props.err ? <div className='modal-txt' dangerouslySetInnerHTML={{__html: msg}}/> : <div className='modal-txt' style={{marginTop: '20px'}}>{msg}</div>}
    </div>
  );
 }
}
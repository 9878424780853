import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import mttlogo from './assets/MTTLogo.svg';
import linkedin from './assets/LinkedinLogo.svg';
import twitter from './assets/TwitterLogo.svg';
import github from './assets/GithubLogo.svg';
import mplogo from './assets/MPLogo.svg';
import mvclogo from './assets/MVCLogo.svg';
import arrow from './assets/TopOfPageArrow.svg';

export class Footer extends Component {
  scrollToTop = () => {
    console.log('here');
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    console.log('hi');
  };

  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <Link className='logo-link' to="/">
            <img
              src={mttlogo}
              className="logo-contianer"
              alt="Minority Think Tank logo"
            />
          </Link>
          <div className="footer-links-container">
            <div className="company-container">
              <div className="footer-heading-font">Company</div>
              <Link to="/about">
                <div className="footer-subheading-font footer-txt-margin">
                  About Us
                </div>
              </Link>
              <a href="https://forms.gle/GGAryj4XP1eUnebL8" target='_blank' rel="noreferrer">
                <div className="footer-subheading-font footer-txt-margin">
                  Join Us
                </div>
              </a>
              <a
                className="footer-subheading-font footer-txt-margin"
                href="https://minorityprogrammers.com/partner"
                target="_blank"
                rel="noreferrer"
              >
                Partnerships
              </a>
            </div>
            <div className="donate-container">
              <div className="footer-heading-font">Donate</div>
              <a
                className="footer-subheading-font footer-txt-margin"
                href="https://www.minorityaccelerator.com/donate"
                target="_blank"
                rel="noreferrer"
              >
                Donate Cash
              </a>
              <a
                className="footer-subheading-font footer-txt-margin"
                href="https://app.endaoment.org/orgs/86-3949072"
                target="_blank"
                rel="noreferrer"
              >
                Donate Crypto
              </a>
              <a
                className="footer-subheading-font footer-txt-margin"
                href="https://minorityaccelerator.com/partner"
                target="_blank"
                rel="noreferrer"
              >
                Donate Time
              </a>
            </div>
            <div className="follow-container">
              <div className="footer-heading-font">Follow Us</div>
              <a
                href="https://www.linkedin.com/company/minoritythinktank/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="follow-logos" style={{ cursor: 'pointer' }}>
                  <img src={linkedin} alt="Linkedin logo" />
                  <div
                    className="footer-subheading-font"
                    style={{ marginLeft: '25px' }}
                  >
                    Linkedin
                  </div>
                </div>
              </a>
              <a
                href="https://twitter.com/think_minority"
                target="_blank"
                rel="noreferrer"
              >
                <div className="follow-logos" style={{ cursor: 'pointer' }}>
                  <img src={twitter} alt="Twitter logo" />
                  <div
                    className="footer-subheading-font"
                    style={{ marginLeft: '25px' }}
                  >
                    Twitter
                  </div>
                </div>
              </a>
              <a
                href="https://github.com/MinorityProgrammers/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="follow-logos" style={{ cursor: 'pointer' }}>
                  <img src={github} alt="Github logo" />
                  <div
                    className="footer-subheading-font"
                    style={{ marginLeft: '25px' }}
                  >
                    Github
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className='footer-right-links'>
            <a
              href="https://minorityprogrammers.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={mplogo}
                className="footer-mplogo"
                alt="Minority Programmers logo"
              />
            </a>
            <a
              href="https://minorityventure.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={mvclogo}
                className="footer-mvclogo"
                alt="Minority Venture Capital logo"
              />
            </a>
          </div>
        </div>
          <img
            src={arrow}
            className="footer-arrow"
            onClick={this.scrollToTop}
            alt="arrow to top of page"
          />
          <div className="footer-copyright">
            © Copyright {new Date().getFullYear()} by{' '}
            <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              Minority Think Tank Foundation | EIN - 86-3949072
            </span>
          </div>
      </div>
    );
  }
}

export default Footer;

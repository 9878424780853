import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import About from './components/About';
import About1 from './components/About1';
import Home from'./components/ThinkTankHome';
import Join from './components/Join';
import Events from './components/Events';
import Publications from './components/Publications';

import data from './data.js';

  // https://github.com/kidjp85/react-id-swiper/issues/85
  // https://github.com/nolimits4web/swiper/issues/2629

  // For adding mailchimp with backend :
  // https://www.freecodecamp.org/news/how-to-integrate-mailchimp-in-a-javascript-web-app-2a889fb43f6f/
  // This one doesnt require backend
  // https://dev.to/itnext/setting-up-a-news-letter-with-react-and-mailchimp-2f6p
  // https://github.com/revolunet/react-mailchimp-subscribe/issues/44
  // https://www.npmjs.com/package/react-mailchimp-subscribe


function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={() => <Home news={data.home.news} programs={data.home.programs}/>}/>
        <Route path='/events' component={() => <Events upcoming={data.events.upcoming} past={data.events.past}/>}/>
        {/* Temporarily commenting out path to publications page */}
        <Route path='/publications' component={() => <Publications featured={data.publications.featured} articles={data.publications.articles} comingsoon={data.publications.comingsoon} foodforthink={data.publications.foodforthink}/>}/>
        <Route path='/about' component={() => <About board={data.about.board} professors={data.about.professors}/>}/>
        <Route path='/about1' component={() => <About1 board={data.about.board} professors={data.about.professors}/>}/>
        <Route path='/join' component={() => <Join board={data.about.board} />}/>
      </Switch>
    </Router>
  );
}


export default App;

import React,{useState} from 'react'
import EmailPopUp from './EmailPopUp';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './ThinkTankHome.css';

 const SubscribeSection = () => {
    const  [email,setEmail]= useState("")
    const  [seen,setSeen]= useState(false)
    const togglePop = () => {
        this.setState({
            seen: !this.state.seen,
        });
    };
    return (
        <div className="home-email-flex-container">
          <div className="home-join-header">Thinking about joining us?</div>
          <div className="home-join-subheader">
            Join the newsletter and get all the updates
          </div>
          <MailchimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <>
                <form
                className='news-email-form'
                  onSubmit={(event) => {
                    event.preventDefault();
                    subscribe({ EMAIL: email });
                    setEmail("")
                    setSeen(true)
                  }}
                >
                  <input
                    type="text"
                    className="email-field home-email-field"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <div className="email-btn-container">
                    <button type="submit" className="email-field-btn">
                      <div className="email-field-btn-txt">Sign up</div>
                    </button>
                  </div>
                </form>
                <div
                  style={{
                    marginTop: '280px',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  {status === 'sending' && seen ? (
                    <EmailPopUp
                      toggle={togglePop}
                      content="Sending..."
                      err={false}
                      style={{ marginTop: '300px' }}
                    />
                  ) : null}
                  {status === 'error' && seen ? (
                    <EmailPopUp
                      toggle={togglePop}
                      content={message}
                      err={true}
                    />
                  ) : null}
                  {status === 'success' && seen ? (
                    <EmailPopUp
                      toggle={togglePop}
                      content="Subscribed!"
                      err={false}
                    />
                  ) : null}
                </div>
              </>
            )}
          />
        </div>
  )
}
export default SubscribeSection
import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './About1.css';
import ProgramCard from './ProgramCard';
import arrow from './assets/ArrowComponent.svg';
import Header from'./HeaderTemp';
import Footer from './Footer';
import EmailPopUp from './EmailPopUp';
import nick from './assets/NickImg.jpg';
import dom from './assets/Dom.png';
import roshaan from './assets/Roshaan.png';
import globe from './assets/Globe.svg';
import icon from './assets/MPICON.svg';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

// To force the swiper loop to update
  setInterval(()=>{
    const swiper = document.querySelectorAll('.swiper-container');
    for (var i = 0; i < swiper.length; i++) {
      const s1 = swiper[i].swiper;
      if (s1 != null) {
        s1.loopDestroy();
        s1.loopCreate();
      }
    }
  },500)


  const mappingBoard = (member, i , selected) => {
    let img = icon;
    if (member.name==='Nick Morris') {
      img=nick;
    }
    else if (member.name==='Dom Coronel') {
      img=dom;
    }
    else if (member.name==='Roshaan Khan') {
      img=roshaan;
    }
    if (selected === 'all') {
      return (
        <SwiperSlide className='item' key={i} style={{width:'364px'}}>
          <ProgramCard key={i} name={member.name} role={member.role} img={img} desc={member.desc}/>
        </SwiperSlide>
      )
    }
    else if (member.category.includes(selected)) {
      return(
        <SwiperSlide className='item' key={i} style={{width:'364px'}}>
          <ProgramCard key={i} name={member.name} role={member.role} img={img} desc={member.desc}/>
        </SwiperSlide>
      )
    }
  }

export class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'all',
      email: '',
      seen: false
    }
    this.boardSelect = this.boardSelect.bind(this);
  }

  togglePop = () => {
    this.setState({
      seen: !this.state.seen
    });
  };

  boardSelect(component) {
    if (this.state.selected !== component) {
      this.setState({ selected: component })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
  return (
    <div className='about-background'>
      <div className='about-header-element'>
        <Header page='about us' />
      </div>

      <div className='about-black-background1'/>
      <div className='about-black-background2'/>

      <div className='about-header-container'>
        <div className='about-page-title'>About Us</div>
        <div className='about-page-subtitle'>leaders in intellectual thought & action, it’s about time we got some traction.</div>
      </div>
      
      <div className='about-why-flex-container'>
        <div className='about-subheader'>Why?</div>
        <div className='about-style-line'/>
        <div className='about-goal-txt'>Minority Think Tank was created with the goal of becoming an epicenter of innovative thought, research, and policy design in our shared mission of empowering underrepresented communities. To reach this goal, we act as an intellectual hub that embraces diversity and interdisciplinary thought in the pursuit of creating practical, real world solutions. We want Minority Think Tank to become a leading research institution for people of all nations and disciplines to come together in their shared goal of creating meaningful and lasting change.</div>
      </div>

      <div className='about-mission-flex-container'>
        <div className='about-subheader' style={{width:'143px', left:'104px'}}>Mission</div>
        <div className='about-style-line' />
        <div className='about-mission-txt'>The Minority Think Tank (MTT) Foundation is an independent 501(c)3 nonprofit, nonpartisan research organization dedicated to empowering humanity through innovative ideas from expert analysis, technology driven solutions, revolutionary thinking, and education for underrepresented communities.</div>
      </div>

      <div className='about-board-flex-container'>
        <div className='about-subheader' style={{width:'222px', left:'25px'}}>Meet Board</div>
        <div className='about-style-line' />
        {/* Add conditionals so that the section that is lit up changes and filters the board members by type */}

        <div className='about-board-style-line'/>
        <div className='about-board-component-container'>
          <div style={{position:'relative',left:'20%'}}><BoardComponents title='all' selected={this.state.selected} width='48px' component ={this}/></div>
          <div style={{position:'relative',left:'28%'}}><BoardComponents title='board' selected={this.state.selected} width='85px' component ={this}/></div>
          <div style={{position:'relative',left:'40%'}}><BoardComponents title='executives' selected={this.state.selected} width='150px' component ={this}/></div>
          <div style={{position:'relative',left:'56%'}}><BoardComponents title='team' selected={this.state.selected} width='80px' component ={this}/></div>
          <div style={{position:'relative',left:'68%'}}><BoardComponents title='volunteers' selected={this.state.selected} width='155px' component ={this}/></div>
          <div style={{position:'relative',left:'85%'}}><BoardComponents title='fellows' selected={this.state.selected} width='114px' component ={this}/></div>
          {/* not included are advisory council, professors, or research team */}
        </div>
        <div className='about-board-container'>
          <Swiper slidesPerView={'auto'} spaceBetween={30} loop={true} autoplay={{
              "delay": 4000,
              "disableOnInteraction": false
            }} className="mySwiper">
            {this.props.board.map((member, i) => mappingBoard(member, i, this.state.selected))}
          </Swiper>
        </div>
      </div>

      <div className='about-fellowship-flex-container'>
        <div className='about-subheader' style={{width:'199px', height:'106px', left:'48px'}}>Fellowship Program</div>
        <div className='about-style-line'/>
        <div className='about-fellowship-txt'>A goal of Minority Think Tank is to help ensure a future with a more diverse workforce in STEM and academia at large. In short, we want to help make sure that there are greater opportunities for everyone to get the tools and training needed to further their academic career. <br/><br/>One way we are working towards this future is with our MTT Fellowship Program. Minority Think Tank is conducting an academic driven fellowship program , where a diverse group of the brightfwest students will be spearheading research on an issue with seasoned academic faculty serving as mentors and advisors.</div>
      </div>

      <div className='about-professors-flex-container'>
        <div className='about-subheader' style={{width:'207px', left:'40px'}}>Professors</div>
        <div className='about-style-line'/>

        {/* IMAGES IN THIS CONTAINER NEED TO BE SET TO CORRECT PEOPLE */}
        <div className='prof-arrow-container'>
          <img src={arrow} className='about-left-arrow aal' alt='right left button'/>
          <div className='prof-container'>
            <Swiper  slidesPerView={3} spaceBetween={0} loop={true}  navigation= {{
              prevEl: '.aal',
              nextEl: '.aar'
            }} className="mySwiper">
              {this.props.professors.map((prof, i) => <SwiperSlide style={{width:'auto'}} key={i}><ProfCard prof={prof}/></SwiperSlide>)}
            </Swiper>
          </div>
          <img src={arrow} className='about-right-arrow aar' alt='right arrow button'/>
        </div>
      </div>

      <div className='about-what-we-do-flex-container'>
        <div className='about-subheader' style={{width:'230px', left:'17px'}}>What We Do</div>
        <div className='about-style-line'/> 
        
        <div className='about-circle-flex-container'>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle'/>
              <div className='circle-border'/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Research</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>From policy to adanced STEM concepts, we add the the global knowledge base one thought at a time .</div>
          </div>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle' style={{background:'#3F22CF'}}/>
              <div className='circle-border' style={{border:'3px solid #3F22CF'}}/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Build</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>You can’t trust any old thought, you gotta do something about it. We do something about. We make ideas comes true.</div>
          </div>

          <div className='about-section-container'>
            <div className='circle-container'>
              <div className='circle' style={{background:'#151371'}}/>
              <div className='circle-border' style={{border:'3px solid #151371'}}/>
            </div>
            <div className='what-we-do-header' style={{top:'170px'}}>Implement</div>
            <div className='what-we-do-txt' style={{top:'225px'}}>Research, Policy, is all cool, but we don’t wait simply push legislators to represent minority though, we go to the community and actually do it.</div>
          </div>
        </div>
      </div>

      <img src={globe} className='about-globe' alt='globe' />
      <div className='about-email-flex-container'>
        <div className='about-join-header'>Thinking about joining the network?</div>
        <div className='about-join-subheader home-font'>Click that button and dive head first! </div>
        <MailchimpSubscribe 
          url={process.env.REACT_APP_MAILCHIMP_URL}
          render={({subscribe, status, message}) => (
            <>
              <form onSubmit={(event)=> {
                event.preventDefault();
                subscribe({ EMAIL:this.state.email });
                this.setState({email:'', seen:true});
              }}>
                <input type='text' className='email-field about-email-field' placeholder='Enter your email address' value={this.state.email} onChange={e => this.setState({email:e.target.value})}></input>
                <div className='about-btn-container'>
                  <button type='submit' className='email-field-btn'><div className='email-field-btn-txt'>Sign up</div></button>
                </div>
              </form>
              <div style={{marginTop:'320px', display:'flex', justifyContent:'center', position:'absolute', width:'100%'}}>
                {status === "sending" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Sending...' err={false}/> : null}
                {status === "error" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content={message} err={true}/> : null}
                {status === "success" && this.state.seen ? <EmailPopUp toggle={this.togglePop} content='Subscribed!' err={false}/> : null}
              </div>
            </>
          )}
        />
      </div>
      <div className='about-spacer' />

      <div className='about-footer-element'>
        <Footer />
      </div>
    </div>
  )}
}

export default About

function BoardComponents( {title, selected, width, component} ) {
  if (selected===title) {
    return (<div className='board-component-container' style={{width:width}}>
      <div className='board-component-txt-yellow' style={{width:width}} onClick={() => {component.boardSelect(title)}}>{title}</div>
      <div className='board-component-line-yellow' style={{width:width}} onClick={() => {component.boardSelect(title)}}></div>
    </div>)
  } else {
    return (<div className='board-component-container' style={{width:width}}>
      <div className='board-component-txt' style={{width:width}} onClick={() => {component.boardSelect(title)}}>{title}</div>
      <div className='board-component-line' style={{width:width}} onClick={() => {component.boardSelect(title)}}></div>
    </div>)
  }
}

function ProfCard(prof) {
  return (
    <div className='prof-card-container'>
      <img src={nick} className='prof-img' alt={prof.prof.name}/>
      <div className='prof-name-txt'>{prof.prof.name}, <br/>{prof.prof.title}</div>
      <div className='prof-small-txt'>{prof.prof.desc}</div>
    </div>
  )
}
